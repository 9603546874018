<template>
  <section>
    <!-- <land-hero-alts
      v-if="banItems.length"
      :titled="false"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    /> -->
    <land-section
      id="content_detail"
      space="20"
    >
      <v-container
        style="max-width: 900px;"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
          >
            <template v-if="imgTypes.indexOf(cntInfo.type) !== -1">
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-img
                    :src="curImg.src"
                    cover
                    height="100%"
                    class="rounded"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  v-for="(img, ind) in othImgs"
                  :key="ind"
                  cols="12"
                  md="2"
                >
                  <v-img
                    :src="img.src"
                    cover
                    height="100%"
                    class="rounded"
                    @click="toSelectImg(img)"
                  />
                </v-col>
              </v-row>
            </template>
            <template v-if="cntInfo.type === 'video'">
              <video-player
                :ref="cntInfo.name"
                class="video-player vjs-custom-skin"
                :playsinline="true"
                :options="cntInfo.options"
                @play="onVideoPlay"
              />
            </template>
            <template v-if="cntInfo.type === 'article'">
              <div
                class="ql-editor"
                v-html="cntInfo.content"
              />
            </template>
          </v-col>
          <v-col
            cols="12"
          >
            <div class="d-flex flex-column">
              <div
                v-if="cntInfo.user && cntInfo.user.userId"
                class="d-flex flex-row mb-6"
              >
                <v-avatar
                  size="56"
                >
                  <v-img
                    :src="cntInfo.user.avatar || defAvatar"
                  />
                </v-avatar>
                <div class="d-flex flex-column ml-4">
                  <div
                    class="co-flex-row co-items-center"
                  >
                    <span class="text-h6 mr-12">
                      {{ cntInfo.user.username }}
                    </span>
                    <v-btn
                      v-if="cntInfo.user.userId !== userInfo.userId"
                      :outlined="btnAttention.outlined"
                      :dark="btnAttention.dark"
                      :color="btnAttention.color"
                      height="28"
                      width="90"
                      @click="onAttention(cntInfo.user)"
                    >
                      <v-icon
                        v-if="btnAttention.icon"
                        left
                      >
                        {{ btnAttention.icon }}
                      </v-icon>
                      {{ btnAttention.text }}
                    </v-btn>
                  </div>
                  <span
                    v-if="cntInfo.user.signature"
                    class="text-body-2 blue-grey--text lighten-3"
                  >
                    {{ cntInfo.user.signature }}
                  </span>
                </div>
              </div>

              <span class="co-text-md mb-2">
                {{ cntInfo.title }}
              </span>
              <v-divider />
              <span class="text-body-2 blue-grey--text lighten-3 mt-1">
                {{ cntInfo.createTime }}
              </span>

              <span
                v-if="cntInfo.description"
                class="co-text-md blue-grey--text lighten-3 my-4"
              >
                {{ cntInfo.description }}
              </span>
              <!-- <div class="co-flex-row co-items-center mt-4">
                <span class="text-body-2 blue-grey--text lighten-3 mr-6">
                  评论
                  <span class="px-1 text-body-1 blue-grey--text darken-4">{{ comCount }}</span>
                  条
                </span>

                <land-icon-forward
                  :name="flagForward.name"
                  :value="flagForward.value"
                  :icon="flagForward.icon"
                  :color="flagForward.color"
                  @action="onAction($event, cntInfo)"
                />
                <land-icon-collect
                  :name="flagCollect.name"
                  :value="flagCollect.value"
                  :icon="flagCollect.icon"
                  :color="flagCollect.color"
                  @action="onAction($event, cntInfo)"
                />
                <land-icon-like
                  :name="flagLike.name"
                  :value="flagLike.value"
                  :icon="flagLike.icon"
                  :color="flagLike.color"
                  @action="onAction($event, cntInfo)"
                />
              </div> -->
              <!-- <div class="co-flex-row co-items-center co-w-full my-4">
                <v-avatar
                  v-if="userInfo.avatar"
                  size="56px"
                  class="mr-6"
                >
                  <v-img
                    :src="userInfo.avatar"
                  />
                </v-avatar>
                <div class="co-flex-row co-items-center co-w-full co-border co-border-major co-round-md co-overflow-hidden">
                  <input
                    v-model="comContent"
                    class="co-border-none co-border-transparent co-py2 co-px8"
                    style="outline: none; flex: 1;"
                    placeholder="发布你的评论"
                  >
                  <v-btn
                    tile
                    x-large
                    :color="btnComment.color"
                    :width="btnComment.width"
                    @click="onSubmitComment"
                  >
                    {{ btnComment.text }}
                  </v-btn>
                </div>
              </div> -->
              <!--
              <div
                class="d-flex flex-column"
              >
                <div
                  v-for="(comItem, comInd) in comItems"
                  :key="comInd"
                  class="d-flex flex-row py-3"
                >
                  <v-avatar
                    size="56px"
                  >
                    <v-img
                      :src="comItem.avatar"
                    />
                  </v-avatar>
                  <div
                    class="co-flex-col ml-6"
                  >
                    <span class="text-body-1 my-1">
                      {{ `${comItem.username}:` }}
                      <span class="pl-2 blue-grey--text lighten-3">{{ comItem.commentContent }}</span>
                    </span>
                    <span class="text-body-2 blue-grey--text lighten-4">
                      <el-popover
                        :ref="`${popReply.name}_${comInd}`"
                        :placement="popReply.placement"
                        :width="popReply.width"
                        trigger="click"
                      >
                        <el-input
                          v-model="popReply.content"
                          :placeholder="popReply.placeholder"
                        >
                          <el-button
                            slot="append"
                            @click="submitReply(comInd, comItem)"
                          >
                            {{ popReply.action }}
                          </el-button>
                        </el-input>
                        <span
                          slot="reference"
                          class="pr-3 text-body-2 primary--text"
                          style="cursor: pointer;"
                        >
                          回复
                        </span>
                      </el-popover>
                      <span
                        v-if="comItem.replyNum > 0"
                        style="cursor: pointer;"
                        @click="onReplyNum(comItem)"
                      >
                        {{ `(${comItem.replyNum}条)` }}
                      </span>
                      <v-icon
                        v-if="comItem.replyNum > 0 && comItem.replyExpanded"
                        color="blue-grey"
                        size="20"
                        class="mr-3"
                        @click="onReplyExpended(comItem)"
                      >
                        {{ icoDown }}
                      </v-icon>
                      <v-icon
                        v-else-if="comItem.replyNum > 0"
                        color="blue-grey"
                        size="20"
                        class="mr-3"
                        @click="onReplyExpended(comItem)"
                      >
                        {{ icoRight }}
                      </v-icon>
                      {{ `${comItem.createTime}` }}
                      <span class="pl-2">{{ `来自${comItem.ipBelong}` }}</span>
                    </span>
                    <div
                      v-if="comItem.replys && comItem.replys.length && comItem.replyExpanded"
                      class="co-flex-col co-bg-gray-100 rounded pa-4 mt-2"
                    >
                      <div
                        v-for="(repItem, repIndex) in comItem.replys"
                        :key="`rep_${repIndex}`"
                        class="co-flex-row my-1"
                      >
                        <span class="co-text-xs co-font-600 co-text-gray-800 mr-2">{{ `${repItem.username}:` }}</span>
                        <span class="co-text-xs co-text-gray-800">{{ repItem.content }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              -->
            </div>
          </v-col>
        </v-row>
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <div class="d-flex flex-row align-center ml-3">
              <span class="blue-grey--text">上一个：</span>
              <span
                v-if="prev.enrollId"
                class="blue--text"
                :class="[prev.enrollId ? 'cursor-handle' : '']"
                @click="toPrev"
              >
                {{ prev.title }}
              </span>
              <span
                v-else
                class="blue-grey--text text--lighten-3"
              >(无)</span>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            sm="12"
          >
            <div
              class="d-flex flex-row justify-end align-center mr-3"
            >
              <span class="blue-grey--text">下一个：</span>
              <span
                v-if="next.enrollId"
                class="blue--text"
                :class="[next.enrollId ? 'cursor-handle' : '']"
                @click="toNext"
              >
                {{ next.title }}
              </span>
              <span
                v-else
                class="blue-grey--text text--lighten-3"
              >(无)</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
    <dialog-alert ref="dlgAlert" />
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import cookie from '@/api/lib/co.lib.cookie'
  // import web from '@/api/web/co.web'

  import mixActivityEnroll from '@/pages/mixins/activity/mix.activity.enroll'

  // import mixPreset from '@/pages/mixins/mix.preset'

  export default {
    metaInfo: { title: '参赛作品' },
    components: {
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    mixins: [
      mixActivityEnroll
    ],
    data () {
      return {
        defAvatar: app.pic.avatar_user,
        icoRight: app.icon.arrow_right,
        icoDown: app.icon.arrow_down,
        imgTypes: [
          'image',
          'pound'
        ]
      }
    },
    created () {
      const nav = JSON.parse(cookie.get('ActivityEnroll'))
      console.log('nav: %o', nav)
      Object.assign(this.navMeta, nav)

      const enrollId = nav.enrollId

      // this.getContentExtend({ contentId })
      // this.configComments({ contentId })
      this.getContent({ enrollId })
    }
  }
</script>

<style scoped>
  .cursor-handle {
    cursor: pointer;
  }
</style>
