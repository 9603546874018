
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'
import user from '@/api/co.user'

import web from '@/api/web/co.web'

import mixPreset from '@/pages/mixins/mix.preset'

const names = mixPreset.Names

export default {
  data () {
    return {
      types: web.comm.ContentTypes,
      cntInfo: {},
      prev: {},
      next: {},
      current: {},
      cntCols: 4,
      navMeta: {},
      userInfo: {},
      curImg: {},
      othImgs: [],
      allImgs: [],
      comParams: null,
      comContent: '',
      comItems: [],
      comCount: 0,
      btnAttention: {
        text: '关注',
        color: 'primary',
        icon: app.icon.plus,
        outlined: true,
        dark: false,
        flag: web.comm.Flags.NO
      },
      popForward: {
        placement: 'bottom',
        action: '转发'
      },
      popReply: {
        name: 'popReply',
        visible: false,
        content: '',
        placement: 'bottom',
        width: '400',
        placeholder: '输入回复内容',
        action: '发送'
      },
      btnComment: {
        text: '评论',
        color: 'primary',
        width: '140'
      },
      currPlayer: null,
      flagForward: {
        name: 'forward',
        value: '',
        icon: app.icon.forward,
        color: 'blue-grey',
        flag: web.comm.Flags.NO
      },
      flagCollect: {
        name: 'collect',
        value: '',
        icon: app.icon.collect,
        color: 'blue-grey',
        flag: web.comm.Flags.NO
      },
      flagLike: {
        name: 'like',
        value: '',
        icon: app.icon.like,
        color: 'blue-grey',
        flag: web.comm.Flags.NO
      },
      handlers: {
        [names.IMAGE]: this.parseImage,
        [names.VIDEO]: this.parseVideo,
        [names.POUND]: this.parseImage,
        [names.ARTICLE]: this.parseArticle,
        [names.ACTIVITY]: this.parseActivity,
        forward: this.toContentForward,
        like: this.toContentLike,
        collect: this.toContentCollect
      }
    }
  },
  created () {
    this.userInfo = user.info.get()
  },
  beforeDestroy () {
    if (this.currPlayer) {
      this.currPlayer.pause()
    }
  },
  methods: {
    toPrev () {
      const enrollId = this.prev.enrollId || ''
      if (enrollId) {
        this.getContent({ enrollId })
      }
    },
    toNext () {
      const enrollId = this.next.enrollId || ''
      if (enrollId) {
        this.getContent({ enrollId })
      }
    },
    showAlert (type, message) {
      const dlg = this.$refs.dlgAlert
      if (dlg) {
        dlg.show({
          type,
          message
        })
      }
    },
    onVideoPlay (player) {
      // console.log('mix.park.video onVideoPlay player: %o', player)
      this.currPlayer = player
    },
    toContentForward (ev, cnt) {
      const me = this
      const text = api.comm.trim(ev.content)
      if (text) {
        const contentId = cnt.contentId
        const executed = function (res) {
          // console.log({ res })
          if (res.status) {
            let value = parseInt(me.flagForward.value, 10) || 0
            value += 1
            me.setForwardFlag({
              forwardedFlag: web.comm.Flags.YES,
              value
            })
            me.$message({
              message: '内容转发成功！',
              type: 'success'
            })
          } else {
            me.$message({
              message: '内容转发失败！',
              type: 'error'
            })
          }
        }

        web.behaviour.forward({
          contentId,
          forwardedText: text,
          executed
        })
      } else {
        this.$alert('填好转发后显示标题才能转发！', '提示', {
          confirmButtonText: '好的',
          type: 'warning'
        })
      }
    },
    toContentLike (ev, cnt) {
      const me = this
      let prefix = ''
      let expect = ''
      const actual = this.flagLike.flag
      let value = parseInt(me.flagLike.value, 10) || 0
      if (actual === web.comm.Flags.NO) {
        value += 1
        prefix = '点赞'
        expect = web.comm.Flags.YES
      } else {
        value -= 1
        prefix = '取消点赞'
        expect = web.comm.Flags.NO
      }

      const executed = function (res) {
        if (res.status) {
          me.setLikeFlag({
            likesFlag: expect,
            value
          })
          // me.$message({
          //   type: 'success',
          //   message: `${prefix}成功！`
          // })
          me.showAlert('success', `${prefix}成功！`)
        } else {
          // me.$message({
          //   type: 'error',
          //   message: `${prefix}失败！`
          // })
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }

      if (actual === web.comm.Flags.NO) {
        web.behaviour.like({
          contentId: cnt.contentId,
          executed
        })
      } else {
        web.behaviour.cancel({
          id: cnt.contentId,
          type: this.flagLike.name,
          executed
        })
      }
    },
    toContentCollect (ev, cnt) {
      const me = this
      let prefix = ''
      let expect = ''
      const actual = this.flagCollect.flag
      let value = parseInt(me.flagCollect.value, 10) || 0
      if (actual === web.comm.Flags.NO) {
        value += 1
        prefix = '收藏'
        expect = web.comm.Flags.YES
      } else {
        value -= 1
        prefix = '取消收藏'
        expect = web.comm.Flags.NO
      }

      const executed = function (res) {
        // console.log({ res })
        if (res.status) {
          me.setCollectFlag({
            collectionFlag: expect,
            value
          })
          // me.$message({
          //   type: 'success',
          //   message: `${prefix}成功！`
          // })
          me.showAlert('success', `${prefix}成功！`)
        } else {
          // me.$message({
          //   type: 'error',
          //   message: `${prefix}失败！`
          // })
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }

      if (actual === web.comm.Flags.NO) {
        web.behaviour.collect({
          contentId: cnt.contentId,
          executed
        })
      } else {
        web.behaviour.cancel({
          id: cnt.contentId,
          type: this.flagCollect.name,
          executed
        })
      }
    },
    onAction (ev, cnt) {
      // console.log('onAction, ev: %o, cnt: %o', ev, cnt)
      const handler = this.handlers[ev.name]
      if (typeof handler === 'function') {
        handler(ev, cnt)
      }
    },
    toLogin () {
      const params = {
        mode: 'phone'
      }
      api.page.navigate(this, 'login', params)
    },
    toAttention (attUser) {
      const me = this
      let prefix = ''
      let expect = ''
      const actual = this.btnAttention.flag
      if (actual === web.comm.Flags.NO) {
        prefix = '加关注'
        expect = web.comm.Flags.YES
      } else {
        prefix = '取消关注'
        expect = web.comm.Flags.NO
      }

      const executed = function (res) {
        if (res.status) {
          me.setAttentionFlag({
            attentionFlag: expect
          })
          // me.$message({
          //   type: 'success',
          //   message: `${prefix}成功！`
          // })
          me.showAlert('success', `${prefix}成功！`)
        } else {
          // me.$message({
          //   type: 'error',
          //   message: `${prefix}失败！`
          // })
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }

      if (actual === web.comm.Flags.NO) {
        web.behaviour.attention({
          attentionUserId: attUser.userId,
          executed
        })
      } else {
        web.behaviour.cancel({
          id: attUser.userId,
          type: 'attention',
          executed
        })
      }
    },
    onAttention (attUser) {
      const me = this
      const callback = function (res) {
        if (res.status) {
          me.toAttention(attUser)
        } else {
          me.$confirm('登录后才加关注, 是否现在登录?', '登录提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'info'
          }).then(() => {
            me.toLogin()
          }).catch(() => {
            me.$message({
              type: 'info',
              message: '已取消登录！'
            })
          })
        }
      }

      user.login.isLogined(callback)
    },
    toSubmitComment () {
      const me = this
      const data = {
        commentContent: this.comContent,
        contentId: this.cntInfo.contentId,
        username: this.userInfo.username,
        avatar: this.userInfo.avatar
      }

      const executing = function () {}
      const executed = function (res) {
        if (res.status) {
          const dst = Object.assign({}, res.data, {
            replyNum: 0,
            replys: [],
            replyExpanded: false
          })
          me.comItems.unshift(dst)

          me.comCount = parseInt(me.comCount, 10) || 0
          me.comCount += 1

          me.comContent = ''
          me.showAlert('success', '评论提交成功')
        } else {
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }
      }
      web.comment.create({
        data,
        executing,
        executed
      })
    },
    checkCommentContent () {
      const val = api.comm.trim(this.comContent)
      if (val) {
        return true
      } else {
        return false
      }
    },
    onSubmitComment () {
      const me = this
      const callback = function (res) {
        if (res.status) {
          if (me.checkCommentContent()) {
            me.toSubmitComment()
          } else {
            me.$alert('填写好评论才能发布，请填写！', '提示', {
              confirmButtonText: '好的',
              type: 'warning'
            })
          }
        } else {
          me.$confirm('登录后才能评论, 是否现在登录?', '登录提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'info'
          }).then(() => {
            me.toLogin()
          }).catch(() => {
            me.$message({
              type: 'info',
              message: '已取消登录！'
            })
          })
        }
      }

      user.login.isLogined(callback)
    },
    submitReply (comInd, comItem) {
      const name = `${this.popReply.name}_${comInd}`
      const frms = this.$refs[name] || []
      const frm = frms[0]

      const me = this
      const data = {
        content: this.popReply.content,
        commentId: comItem.commentId,
        username: this.userInfo.username,
        avatar: this.userInfo.avatar,
        mobile: this.userInfo.mobile
      }

      const executing = function () {}
      const executed = function (res) {
        if (res.status) {
          // me.$message({
          //   message: '你的回复提交成功！',
          //   type: 'success'
          // })
          me.showAlert('success', '回复提交成功')
        } else {
          // me.$message({
          //   message: '你的回复提交失败！',
          //   type: 'error'
          // })
          const err = res.data || {}
          me.showAlert('error', `${err.code}-${err.desc}`)
        }

        if (frm) {
          frm.doClose()
        }
      }
      web.reply.create({
        data,
        executing,
        executed
      })
    },
    toSelectImg (item) {
      const ind = item.index
      this.selectImage(ind)
    },
    selectImage (ind) {
      this.othImgs = []
      const cnt = this.allImgs.length
      for (let i = 0; i < cnt; i++) {
        const src = this.allImgs[i]
        if (ind === i) {
          this.curImg = {
            index: i,
            src
          }
        } else {
          this.othImgs.push({
            index: i,
            src
          })
        }
      }
    },
    parseImage (data) {
      const key = this.navMeta.key || ''
      const arr = key.split('-')
      const ind = parseInt(arr[2]) || 0

      this.allImgs = data.thumbs || []
      if (this.allImgs.length > 2) {
        this.cntCols = 4
      } else if (this.allImgs.length === 2) {
        this.cntCols = 6
      } else {
        this.cntCols = 12
      }

      this.selectImage(ind)
    },
    parseVideo (data) {
      const cnt = data.content || {}
      const options = api.comm.deepClone(mixPreset.PlayerConfig)
      options.poster = data.cover
      options.sources = [{
        type: 'video/mp4',
        src: cnt.path || ''
      }]
      this.cntInfo.options = options
      this.cntInfo.name = `ref_vid_${data.contentId}`
    },
    parseArticle (data) {

    },
    parseActivity (data) {

    },
    toContent (part, content) {
      const name = mixPreset.DetailName
      const params = {
        name: part.name,
        contentId: content.contentId,
        type: content.type,
      }
      lib.cookie.set(name, JSON.stringify(params))
      Object.assign(this.navMeta, params)
    },
    configComments ({
      contentId
    }) {
      const me = this
      const executed = function (res) {
        // console.log('configComments res: %o', res)
        if (res.status) {
          me.comCount = res.total
        }
      }

      this.comParams = web.comment.getParams({
        contentId,
        caches: this.comItems,
        executed
      })
    },
    loadComments (reload = true) {
      this.comParams.reload = reload
      api.httpx.getItems(this.comParams)
    },
    onReplyNum (comItem) {
      this.getReplys(comItem)
    },
    onReplyExpended (comItem) {
      if (comItem.replyExpanded) {
        comItem.replyExpanded = false
      } else {
        this.getReplys(comItem)
      }
    },
    getReplys (comItem) {
      let params = null
      const executed = function (res) {
        comItem.replyExpanded = true
        comItem.replys = params.items
      }
      params = web.reply.getParams({
        commentId: comItem.commentId,
        executed
      })
      api.httpx.getItems(params)
    },
    setFlagValue (data) {
      this.flagForward.value = data.forwardedNum || 0
      this.flagCollect.value = data.collectNum || 0
      this.flagLike.value = data.likesNum || 0
    },
    setCollectFlag (data) {
      const collectFlag = data.collectionFlag || web.comm.Flags.NO
      const value = data.value

      let flag = null
      if (collectFlag === web.comm.Flags.NO) {
        flag = web.flags.no['collect']
      } else {
        flag = web.flags.yes['collect']
      }
      this.flagCollect = Object.assign({}, this.flagCollect, flag)
      this.flagCollect.flag = collectFlag
      if (value) {
        this.flagCollect.value = value
      }
    },
    setForwardFlag (data) {
      const forwardFlag = data.forwardedFlag || web.comm.Flags.NO
      const value = data.value

      let flag = null
      if (forwardFlag === web.comm.Flags.NO) {
        flag = web.flags.no['forward']
      } else {
        flag = web.flags.yes['forward']
      }
      this.flagForward = Object.assign({}, this.flagForward, flag)
      this.flagForward.flag = forwardFlag
      if (value) {
        this.flagForward.value = value
      }
    },
    setLikeFlag (data) {
      const likeFlag = data.likesFlag || web.comm.Flags.NO
      const value = data.value

      let flag = null
      if (likeFlag === web.comm.Flags.NO) {
        flag = web.flags.no['like']
      } else {
        flag = web.flags.yes['like']
      }
      this.flagLike = Object.assign({}, this.flagLike, flag)
      this.flagLike.flag = likeFlag
      if (value) {
        this.flagLike.value = value
      }
    },
    setFlags (data) {
      this.setForwardFlag(data)
      this.setCollectFlag(data)
      this.setLikeFlag(data)
    },
    setAttentionFlag (data) {
      const flag = data.attentionFlag || web.comm.Flags.NO
      if (flag === web.comm.Flags.NO) {
        this.btnAttention = {
          text: '关注',
          color: 'primary',
          icon: app.icon.plus,
          outlined: true,
          dark: false,
          flag
        }
      } else {
        this.btnAttention = {
          text: '取消关注',
          color: 'blue-grey lighten-3',
          icon: '',
          outlined: true,
          dark: false,
          flag
        }
      }
    },
    getAttentionFlag ({
      userId
    }) {
      const me = this
      const executed = function (res) {
        me.setAttentionFlag(res.data)
      }

      user.info.attentionFlag({
        userId,
        executed
      })
    },
    getContentExtend ({
      contentId
    }) {
      const me = this
      const executed = function (res) {
        me.setFlags(res.data)
      }

      user.info.extendContent({
        contentId,
        executed
      })
    },
    getContent ({
      enrollId
    }) {
      const me = this
      const executing = function () {

      }
      const executed = function (res) {
        // console.log('mix.activity.enroll executed, res: %o', res)
        if (res.status) {
          const data = res.data || {}
          me.prev = data.previous || {}
          me.next = data.next || {}
          me.current = data.current || {}

          me.cntInfo = web.activityEnroll.convertItem(me.current)
          me.getAttentionFlag({
            userId: me.cntInfo.createBy
          })
          const type = me.cntInfo.type
          const handler = me.handlers[type]
          if (typeof handler === 'function') {
            handler(me.cntInfo)
          }
        }
      }

      web.activityEnroll.getCurrent({
        id: enrollId,
        executing,
        executed
      })
    },
  }
}
